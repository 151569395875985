import MainSlider, { SwiperSlide } from '@/theme/components/MainSlider'
import { Image } from '@/theme/components/Image'
import { useAppContext } from '@/core/App/AppContext'
import React, { useEffect, useRef, useState } from 'react'
import Link from '@/core/Link'
import { useRouterContext } from '@/core/Router/RouterContext'
import ModalComponent from '@/theme/components/ModalComponent'
import useAuthContext from '@/theme/components/Contexts/AuthContext'
import Section from '../../../../../src/theme/default/components/Section/index'
import { setReferralCode } from '@/utils/referral'
import './home-page.scss'
import ProductListAsync from '@/theme/components/ProductListAsync'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import HomePageTvComponentAsync from '@/theme/components/HomePageTvComponentAsync'
import { sendPageView } from 'src/services/gtm'
import PersistanceStorage from '@/utils/storage'

import ShopbyCategory from '@/theme/components/ShopbyCategory'
import we from 'src/services/webengage'
import AyurvedicScience from '@/theme/components/AyurvedicScience/AyurvedicScience'
import GQLQueryAsync from '@/theme/components/GQLQueryAsync'
import { gql } from 'graphql-request'
import RenderOnView from '@/theme/components/RenderOnView'
import useSpinwheelSession from 'src/hooks/useSpinwheelSession'
import { mohaVideos } from 'src/graphql/queries/homePage'
import VideoEmbed from 'src/framework/components/VideoEmbed'
import { mediaUrl } from 'src/server/utils'

import DiscoverImage from '../../../../../src/assets/discover.jpg'
import ClubMemberPlan from '@/theme/components/ClubMemberPlan'
import Consult from '@/theme/components/Consult'
import NewsCard from '@/theme/components/NewsCart'
import SubscriptionPlans from '@/theme/components/SubscriptionPlans'
import ErrorBoundary from '@/core/ErrorBoundary'
import HeadingViewAll from '@/theme/components/HeadingViewAll'
import { FEAT_SUBSCRPTION_ACTIVE } from 'src/config'

const storage = new PersistanceStorage()

function getYoutubeVideoID(url) {
  let VID_REGEX =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  var match = url.match(VID_REGEX)
  return match ? match[1] : null
}

// const SpinningWheelOffer = React.lazy(() => import('./SpinningWheelOffer'))

function playVideo(bannerThumbnail, videoParentRef) {
  videoParentRef.style.display = 'unset'
  bannerThumbnail.style.display = 'none'
  videoParentRef.src = videoParentRef.src.replace('autoplay=0', 'autoplay=1')
}

const concernCategoriesGQL = gql`
  query {
    categoryList(filters: { url_key: { eq: "concern" } }) {
      name
      include_in_menu
      children {
        name
        url_key
        id
        include_in_menu
      }
    }
  }
`

const homepageTvQuery = gql`
  query {
    ${mohaVideos({ pageSize: 8, currentPage: 1 })}
  }
`

const homepagePostsGql = gql`
  query GetPosts($url_key: String!) {
    mpBlogTopics(filter: { url_key: { eq: $url_key } }) {
      items {
        name
        posts {
          items {
            name
            url_key
            image
            meta_description
          }
        }
      }
    }
  }
`
const homepagePostsGql2 = gql`
  query getHomePageBlogs {
    mpBlogPosts(action: "get_homeblog_post_list") {
      items {
        image
        post_id
        name
        url_key
      }
    }
  }
`

const mostLovedProductsQuery = { category_id: { eq: '50' } }

const articlesData = [
  {
    article:
      'https://elle.in/beauty-launches-that-have-us-in-a-chokehold-this-october/?fbclid=PAAaZvnzlF6LzsdmetoJX3HNO0NxPuO10AQo-Ay6lMXtGP7PAViBDaS0ZaxX0_aem_AYAC1EaWKpSdiMhbZ8Ap4mOV1jN0wc-ZLiWemydpmppWq5ztPpc--skngmf93ni2gqQ',
    logo: 'https://elle.in/wp-content/uploads/2021/11/logo-svg.svg',
    heading: '17 New Beauty Launches That Have Us In A Chokehold This October',
  },
  {
    article:
      'https://m.dailyhunt.in/news/india/english/hello+entrepreneurs-epaper-dhc4bd36087cec49cd9f75d3d1f8df3a02/embrace+the+ayurvedic+revolution+for+deeper+winter+sleep-newsid-dhc4bd36087cec49cd9f75d3d1f8df3a02_5f2ceb70965511eeba756da758e2f2df?sm=Y',
    logo: 'https://m.dailyhunt.in/assets/img/desktop/logo.svg?mode=pwa&ver=4.0.104',
    heading: 'Embrace the Ayurvedic Revolution for Deeper Winter Sleep',
  },
  {
    article:
      'https://www.marketinginasia.com/revolutionize-winter-skincare-with-mohas-new-hydrating-face-serum/',
    logo: 'https://www.marketinginasia.com/wp-content/uploads/2023/01/MIA-Favicon-3D-1.png',
    heading:
      'Revolutionize Winter Skincare with moha’s New Hydrating Face Serum',
  },
  {
    article:
      'https://boldoutline.in/navratri-makeup-trends-2023-your-guide-to-mesmerizing-looks-and-skincare-rituals.html',
    logo: 'https://boldoutline.in/wp-content/uploads/2018/03/BO-logo-145.png',
    heading:
      'Navratri Makeup Trends 2023: Your Guide To Mesmerizing Looks And Skincare Rituals',
  },
  {
    article:
      'https://timesofindia.indiatimes.com/videos/etimes/bollywood/from-sussanne-khan-to-divya-dutta-celebs-clicked-at-an-event-in-mumbai/videoshow/104924237.cms',
    logo: mediaUrl('codilar/tmp/banner/images/toi.webp'),
    heading:
      'From Sussanne Khan to Divya Dutta, celebs clicked at a Charak Pharma event in Mumbai',
  },
]

const VideoSlide = ({
  url = null,
  label = null,
  width = null,
  dimWidth = null,
  className = '',
  onClick = null,
  loading,
  isActive = false,
  thumbnail = null,
}) => {
  const homeBannerThumbnail = useRef(null)
  const bannerVideoRef = useRef(null)
  const searchTerm = `${process.env.APP_MAGENTO_ADMIN_URL}media`
  let parsedUrl = url?.replace(searchTerm, '')
  parsedUrl = parsedUrl?.replace('https://sm.vedistry.com/media/', '')
  parsedUrl = parsedUrl?.replace('https://vedistry-media.b-cdn.net/media/', '')
  parsedUrl = new URL(parsedUrl)
  parsedUrl.searchParams.append('autoplay', '0')
  parsedUrl.searchParams.append('mute', '1')
  parsedUrl.searchParams.append('loop', '1')
  parsedUrl.searchParams.append('controls', '0')
  parsedUrl.searchParams.append(
    'playlist',
    getYoutubeVideoID(parsedUrl.toString())
  )
  return (
    <div
      className="slide--video"
      onClick={() =>
        playVideo(homeBannerThumbnail?.current, bannerVideoRef?.current)
      }
    >
      <img
        src={thumbnail}
        alt=""
        className="home-banner-thumbnail"
        loading="lazy"
        ref={homeBannerThumbnail}
      />
      <iframe
        className="post--video slide--item"
        src={parsedUrl.toString()}
        ref={bannerVideoRef}
        loading="lazy"
      ></iframe>
    </div>
  )
}

const BannerSlider = ({ banners }) => {
  const hasVideoSlide = banners?.find(
    (bnSlide) => bnSlide?.resource_type == 'youtube_video'
  )
  let autoplay = {
    delay: 20000,
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
  }

  return (
    <>
      <MainSlider
        delay={4000}
        spaceBetween={50}
        slidesPerView={1}
        fade={false}
        className="home-banner-image"
        autoplay={true}
        lazy={true}
        style={{ '--itemsCount': banners?.length }}
      >
        {banners &&
          banners.map((banner, key) => (
            <SwiperSlide key={key}>
              {({ isActive }) => (
                <>
                  {banner?.resource_type == 'youtube_video' ? (
                    <VideoEmbed
                      key={banner.resource_path}
                      src={banner.resource_path}
                      label={banner.alt_text}
                      aspectRatio={'40/11'}
                      aspectRatioTablet={'43/46'}
                      aspectRatioMobile={'43/46'}
                      loading={'lazy'}
                      isActive={isActive}
                      thumbnailSrc={mediaUrl(
                        'codilar/tmp/banner/images/home-banner-thumbnail_1_.webp'
                      )}
                    />
                  ) : (
                    <Link
                      href={banner.link}
                      onClick={() => {
                        we.event('Banner Clicked', {
                          'Banner Name': `${banner?.title}`,
                          'Banner Category': `${banner?.banner_category}`,
                          Offer: `${banner?.offer}`,
                        })
                      }}
                    >
                      <Image
                        aspectRatio={'40/11'}
                        aspectRatioTablet={'43/46'}
                        aspectRatioMobile={'43/46'}
                        key={banner.resource_path}
                        url={banner.resource_path}
                        label={banner.alt_text}
                        loading={'lazy'}
                      />
                    </Link>
                  )}
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </>
              )}
            </SwiperSlide>
          ))}
      </MainSlider>
      <div className="container slide-bottom-content slideContent p-2 text-center pb-3">
        <div className="heading-view-all-section headtext">
          <h2 className="mb-0">Ayurveda Expertise Over 7 Decades</h2>
        </div>
        <p>
        Vedistry is part of Charak Pharma, with a heritage rooted in Ayurvedic expertise & trust since 1947. Our cutting-edge facilities & products backed by decades of dedicated research and clinical excellence.
        </p>
        <p>Our unwavering commitment to quality ensures that every product and service is crafted with meticulous attention delivering effective solutions for chronic ailments and lifestyle disorders through Remedies, Diet & Lifestyle.</p>
      </div>
      <div className="text-center container gif-desktop--new">
        <Link href="/healthmantra">
          <img
            src="https://www.vedistry.com/media/vhm-banner-desktop.gif"
            alt=""
          />
          {/* <video
            src="https://vz-c28c3d0b-013.b-cdn.net/6cd60a44-b8be-42a5-9a98-0230deadb26a/play_720p.mp4"
            autoPlay
            muted
            className="w-100"
          /> */}
        </Link>
      </div>
      <div className="text-center container gif-mobile--new">
        <Link href="/healthmantra">
          <img
            src="https://www.vedistry.com/media/vhm-banner-mobile.gif"
            alt=""
          />
        </Link>
      </div>
    </>
  )
}

const SummerDealsCard = (props) => {
  return (
    <Link href={props.href}>
      <figure className="card border-0">
        <img src={props.image} alt={props.title} />
      </figure>
    </Link>
  )
}

const SummerDeals = () => {
  return (
    <div className="summer-deals">
      <div className="summer-deals-cards">
        <SummerDealsCard
          title="Super deal"
          desc="FLAT 10% OFF"
          href="/shop/category/single-herbs"
          image={mediaUrl('uploads/deal-banner-01.webp')}
        />
        <SummerDealsCard
          title="Degestive Care"
          desc="Buy 1, get 10% OFF"
          href="/dipya-syrup-ayurvedic-digestive-care"
          image={mediaUrl('uploads/deal-banner-02.webp')}
        />
        <SummerDealsCard
          title="Better Sleep"
          desc="Buy 1, get 10% OFF"
          href="/kofol-chewable-tablets"
          image={mediaUrl('uploads/deal-banner-03.webp')}
        />
        <SummerDealsCard
          title="Immunity &amp; Throat Care"
          desc="FLAT 10% OFF"
          href="/zzowin-nutra-tablet"
          image={mediaUrl('uploads/deal-banner-04.webp')}
        />
      </div>
    </div>
  )
}

const HomePage = (props) => {
  const { banners, refcode, jsonLd, videos, canonical } = props
  const [{ isMobile, isTablet, isDesktop }] = useAppContext()
  const [app, setApp] = useState(null)
  const router = useRouterContext()
  const auth = useAuthContext()
  const { status, isChecked } = useSpinwheelSession()
  const [spinwheelReady, setSpinwheelReady] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (isChecked) {
        setSpinwheelReady(status !== 'used')
      }
    }, 3000)
  }, [status, isChecked])

  const [hideSpinWheel] = useState(() => {
    return storage.getItem('spunthewheel_may_2') == 'true'
  })
  const [open, openModal] = useState(true)
  const [offerContent, setOfferContent] = useState(null)
  //   const [
  //     {
  //       storeConfig: { default_title, default_description },
  //     },
  //   ] = useAppContext()

  useEffect(() => {
    setApp(true)
    if (refcode) {
      setReferralCode(refcode)
    }
    const offerBool = sessionStorage.getItem('offerBool')
    !offerBool &&
      setTimeout(() => {
        graphqlWebClient
          .request(
            `
        {
          cmsBlocks(identifiers: "homepage_popup") {
          items {
              identifier
              title
              content
              }
            }
          }
        `
          )
          .then(({ cmsBlocks: { items } }) => {
            items[0] && setOfferContent(items[0].content)
          })
          .catch((e) => {})
      }, 3000)
    return () => {}
  }, [])
  const [tabFilters, setTabFilters] = useState({})
  const [currentProTab, setCurrentProTab] = useState({})

  function onTabCategoryChange(category) {
    if (category?.id) {
      setTabFilters((prev) => {
        prev = {
          ...prev,
          category_id: {
            eq: category.id,
          },
        }
        return prev
      })
      setCurrentProTab(category)
    }
  }

  useEffect(() => {
    sendPageView('HomePage')
  }, [])

  return (
    <>
      <div className="home-page-wrapper header-height">
        <BannerSlider
          banners={(banners || []).filter((data) =>
            data.is_mobile == isMobile ? 1 : 0
          )}
        />
      </div>
      <Section title="Deals Of The Day" titleClass="headtext">
        <SummerDeals />
      </Section>
      <ProductListAsync
        filter={{ category_id: { eq: '50' } }}
        pageSize={5}
        title="Our Bestsellers"
        categorySlider={false}
      />
      {/* <RenderOnView>
      </RenderOnView> */}
      <div className="container">
        <RenderOnView>
          <DiscoveringOurRoots />
          {/* <Section title="Discovering Us"></Section> */}
        </RenderOnView>

        <RenderOnView>
          <Section title="Shop by Category">
            <ShopbyCategory />
          </Section>
        </RenderOnView>
        {/* <AboutSection RIGHT_ICON={RIGHT_ICON} /> */}
        {FEAT_SUBSCRPTION_ACTIVE ? (
          <Section>
            <RenderOnView>
              <SubscriptionPlans hideFallback={true} />
            </RenderOnView>
          </Section>
        ) : null}
        <RenderOnView>
          <ProductListAsync
            filter={{ new_arrival: { eq: '1' } }}
            pageSize={5}
            title="Newly Launched"
            categorySlider={false}
            link={'/shop'}
          />
        </RenderOnView>

        <Section
          class="experts-section"
          titleClass="hidden-md"
          title="Speak to our Ayurveda Experts"
        >
          <div className="row align-items-center">
            <div className="col-lg-6">
              <Image
                src={mediaUrl('uploads/SPeak-to-our-ayurveda-experts.webp')}
                aspectRatio={'366/263'}
              />
            </div>
            <div className="col-lg-6">
              <Consult />
            </div>
          </div>
        </Section>

        <Section
          class="concern-slider"
          title="Shop By Concern"
          link={
            currentProTab?.name
              ? `/shop/concern/${currentProTab?.url_key}`
              : null
          }
        >
          <GQLQueryAsync
            query={concernCategoriesGQL}
            onLoad={(res) => {
              if (res?.categoryList?.length && res?.categoryList[0]) {
                setCurrentProTab(res?.categoryList[0]?.children[0])
              }
            }}
          >
            {(res) => (
              <>
                {/* {JSON.stringify({ currentProTab })} */}
                {currentProTab?.id ? (
                  <ProductListAsync
                    pageSize={5}
                    filter={{
                      category_id: {
                        eq: currentProTab?.id,
                      },
                    }}
                    onCategoryChange={(e) => {
                      onTabCategoryChange(e)
                    }}
                    categorySlider={true}
                    categories={
                      res?.categoryList?.length
                        ? res?.categoryList[0].children
                        : null
                    }
                  />
                ) : null}
              </>
            )}
          </GQLQueryAsync>
        </Section>

        {/* <TestimonialsSilder /> */}
        {/* <HomePageReviewsAsync /> */}
        <Section title="Vedistry TV" link="/tv">
          <GQLQueryAsync query={homepageTvQuery}>
            {(tvresponse: any) => (
              <>
                {tvresponse?.showAllVideo?.allVideos?.length ? (
                  <HomePageTvComponentAsync
                    videos={tvresponse?.showAllVideo?.allVideos}
                  />
                ) : null}
              </>
            )}
          </GQLQueryAsync>
        </Section>
        <Section title="Latest Blogs" link="/blog">
          <GQLQueryAsync
            query={homepagePostsGql2}
            variables={{ url_key: 'latest-posts' }}
          >
            {(response) => (
              <div>
                {response?.mpBlogPosts?.items?.length ? (
                  <>
                    <AyurvedicScience
                      title={null}
                      posts={response?.mpBlogPosts?.items}
                    />
                  </>
                ) : null}
              </div>
            )}
          </GQLQueryAsync>
        </Section>

        <RenderOnView>
          <Section title="News & Article" link="/news-and-articles">
            <MainSlider
              spaceBetween={40}
              slidesPerView={isMobile ? 1.2 : 4}
              fade={false}
              lazy={true}
              autoHeight={true}
            >
              {articlesData.map((item) => {
                return (
                  <SwiperSlide key={item.heading}>
                    <NewsCard {...item} />
                  </SwiperSlide>
                )
              })}
            </MainSlider>
          </Section>
        </RenderOnView>
      </div>
      {offerContent && (
        <ModalComponent
          isOpen={open}
          close={(data) => {
            openModal(false)
            sessionStorage.setItem('offerBool', 'true')
          }}
          title={''}
          className="offer-modal-wrapper"
        >
          <div
            className="offer-modal"
            dangerouslySetInnerHTML={{ __html: offerContent }}
          ></div>
        </ModalComponent>
      )}
    </>
  )
}
export default HomePage

function ChevronIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 570 570"
    >
      <circle cx="285" cy="285" r="285" fill="#000"></circle>
      <path
        stroke="#fff"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="48"
        d="M297 141l144 144-144 144m124-144H129"
      ></path>
    </svg>
  )
}

function AboutSection({ RIGHT_ICON }) {
  return (
    <div className="mid-section-banner">
      <MainSlider delay={4500} spaceBetween={50} slidesPerView={1} fade={false}>
        <SwiperSlide>
          <Link href="/about-us">
            <div className="text-section">
              <div className="text-wrapper">
                <p className="mid-section-title">Discovering Us</p>
                <p className="mid-section-content mb-4">
                  Vedistry is an offshoot of Charak Pharma established in 2018
                  in order to be present in the D2C (direct-to-consumer)
                  segment. Vedistry derives its strengths from the over 7
                  decades of experience of manufacturing and marketing of
                  Ayurvedic Products by Charak Pharma
                </p>
                <br />
                <ChevronIcon />
              </div>
            </div>
            <div className="image-carousel-section">
              <div className="mid-section-image-wrapper">
                <Image
                  aspectRatio={'1/1'}
                  url={mediaUrl('codilar/tmp/banner/images/about-us1.webp')}
                  alt={'About us'}
                  className="mid-section-carousel-image"
                />
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link href="/about-us">
            <div className="text-section">
              <div className="text-wrapper">
                <p className="mid-section-title">Discovering us</p>
                <p className="mid-section-content">
                  Vedistry’s vision is to become a trusted brand offering
                  efficacious Ayurvedic health and personal care products at
                  affordable rates with a mission to consistently provide
                  high-quality, scientific, well-documented, evidence-based, and
                  standardized formulations based on the principles of ancient
                  Ayurveda combined with Modern Nutraceuticals.
                </p>
                <img src={RIGHT_ICON} alt="" />
              </div>
            </div>
            <div className="image-carousel-section">
              <div className="mid-section-image-wrapper">
                <img
                  src={mediaUrl('codilar/tmp/banner/images/about-us2.webp')}
                  alt=""
                  loading="lazy"
                  className="mid-section-carousel-image"
                />
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link href="/about-us">
            <div className="text-section">
              <div className="text-wrapper">
                <p className="mid-section-title">Discovering us</p>
                <p className="mid-section-content">
                  Consumers purchase Ayurveda products based on the quality and
                  reputation associated with a brand. Brand Charak has huge
                  credibility and strong equity amongst nationwide medical
                  practitioners and has gained the tremendous trust of millions
                  of satisfied customers over decades of its presence.
                </p>
                <img src={RIGHT_ICON} alt="" />
              </div>
            </div>
            <div className="image-carousel-section">
              <div className="mid-section-image-wrapper">
                <img
                  src={mediaUrl('codilar/tmp/banner/images/about-us2.webp')}
                  alt=""
                  loading="lazy"
                  className="mid-section-carousel-image"
                />
              </div>
            </div>
          </Link>
        </SwiperSlide>
      </MainSlider>
    </div>
  )
}

function DiscoveringOurRoots({ title = 'Discover Vedistry' }) {
  return (
    <div className="image-text-button-section">
      <div>
        <HeadingViewAll title={title} className="hidden-md" />
      </div>
      <div className={`image-text-inner-section`}>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img
              className="img-fluid w-100"
              src={
                'https://vedistry-media.b-cdn.net/media/uploads/discover-us.jpg'
              }
              alt="discover"
            />
          </div>
          <div className="col-lg-6">
            <div className="content">
              <HeadingViewAll title={title} className="hidden-xs" />
              <p className="pt-3 image-text-inner-section-para ">
                <i className="fw-semibold">Vedistry </i>a new venture of Charak Pharma , aims to bridge the traditional wisdom of Ayurveda with modern consumer needs, bringing high-quality products & services directly to you. Drawing strength from Charak Pharma’s 70+ years of experience in world class manufacturing & research Vedistry offers trusted Ayurveda partners in <strong> health, dedicated </strong> to supporting and enhancing you & your family well-being.
              </p>
              <div className="text-md-start text-center">
                <Link href="/about-us">
                  <span className="discover-btn mt-4">About Us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
