import { gql } from 'graphql-request'
import price_range from './product/price_range'
import product_items, { bundleProduct } from './product/product_items'
const slider_id = 1 // this is for homepagePage Banners

export const getHomeSliderQuery = gql`{
  codilarBannerSlider(sliderId:${slider_id}) {
    banners {
      slider_id
      title
      resource_path
      resource_type
      is_mobile
      alt_text
      link
    }
  }
}
`

export const homepageQuery = (...data) => gql`
  {
    route(url: "/") {
      relative_url
      ... on CmsPage {
        title
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

const homePageProducts = (key) => `
  ${key}{
    allProducts {
      sku
      prod_type
      url_key
      name
      price
      special_price
      img
      discount
      rating
      bogo
      pack_size{
        size
        price
        sku
      }
    }
  }
`
export const mohaVideos = ({ pageSize = 8, currentPage = 1 }) => `
    showAllVideo(pageSize:${pageSize},currentPage:${currentPage}){
      allVideos{
        viewcount
        description
        link
        identifier
        title
        video_id
        duration
        thumbnail
        created_at
        url_key
      }
    }
`
const popularVideo = `
popularVideos{
  viewcount
  link
  title
  thumbnail
}`

export const popularVidoes = (order) => `{
  popularVideo(order:"${order}"){
      ${popularVideo}
  }
}
`

// categories{
//   items{
//     children{
//       id
//       name
//       children{
//         name
//         children{
//           name
//         }
//       }
//     }
//   }
// }
