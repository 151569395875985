import { FC } from "react";
import style from '../components.module.css'
import { Image } from '@/theme/components/Image'
import { useState } from "react";
import { mediaUrl } from "src/server/utils";
import { useAppContext } from "@/core/App/AppContext";
// import ReactPlayer from 'react-player/youtube'


function getYoutubeVideoID(url) {
  let VID_REGEX =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  var match = url.match(VID_REGEX)
  return match ? match[1] : null
}

function PlayIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="164"
      height="164"
      fill="none"
      viewBox="0 0 164 164"
      className={props.className}
    >
      <circle cx="82" cy="82" r="82" fill="#67E1CB"></circle>
      <path fill="#fff" d="M134 81.5l-78.75 45.466V36.034L134 81.5z"></path>
    </svg>
  );
}

const VideoEmbed: FC<{ src: string, thumbnailSrc?: string, aspectRatio?: string, aspectRatioMobile?: string, isPlaying?: boolean, showPlayIcon?: boolean, rounded?: boolean }> = (props) => {
  let parsedSrc = props.src
  parsedSrc = parsedSrc?.replace('https://sm.vedistry.com/media/', '')
  parsedSrc = parsedSrc?.replace('https://vedistry-media.b-cdn.net/media/', '')
  let url = new URL(parsedSrc)
  url.searchParams.append('autoplay', '1')
  url.searchParams.append('mute', '0')
  url.searchParams.append('loop', '1')
  url.searchParams.append('controls', '0')
  url.searchParams.append(
    'playlist',
    getYoutubeVideoID(url.toString())
  )
  const [ videoUrl, setVideoUrl ] = useState(null)
  const [ isPlaying, setIsPlaying ] = useState(props.isPlaying)
  const playVideo = (_url) => {
    if (isPlaying) {
      setVideoUrl(null)
      setIsPlaying(false)
    } else {
      setVideoUrl(url)
      setIsPlaying(true)
    }

  }
  let aspectRatio = props.aspectRatio
  const [ { isMobile } ] = useAppContext()
  if (isMobile && props.aspectRatioMobile) {
    aspectRatio = props.aspectRatioMobile
  }

  return (
  <div className={style.VideoEmbed} style={{ '--aspect-ratio': aspectRatio, '--aspect-ratio-mobile': props.aspectRatioMobile, '--border-radius': props.rounded ? '1rem': '0' }} onClick={() => playVideo(url)}>
    {/* {JSON.stringify(props)} */}
    {!isPlaying ? (
    <Image url={props.thumbnailSrc} aspectRatio={aspectRatio} />
    ): null}

    {(props.showPlayIcon && !isPlaying) ? (
      <PlayIcon className="PlayIcon" />
    ):null}
    {/* <ReactPlayer url={url.toString()} playing={isPlaying} /> */}
    <iframe
        title={videoUrl}
        frameBorder="0"
        allowFullScreen
        loading="lazy"
        src={videoUrl}
    />
  </div>
  )
}

export default VideoEmbed
